export const photos = {
    day: [
        'image-01.jpg',
        'image-02.jpg',
        'image-03.jpg',
        'image-04.jpg',
        'image-05.jpg',
        'image-06.jpg',
        'image-07.jpg',
        'image-08.jpg',
        'image-09.jpg'
    ],
    night: [
        'image-01.jpg',
        'image-02.jpg',
        'image-03.jpg',
        'image-04.jpg',
        'image-05.jpg',
        'image-06.jpg',
        'image-07.jpg',
        'image-08.jpg'
    ]
};