import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

import './App.scss';
import { photos } from "./photos";

const PhotoNavigation = (props) => {
  const { photoIndex, setPhotoIndex, dayNight } = props;
  const prevImage = () => setPhotoIndex(photoIndex > 0 ? photoIndex - 1 : 0);
  const nextImage = () => setPhotoIndex(photoIndex < photos[dayNight].length - 1  ? photoIndex + 1 : photos[dayNight].length - 1);

  return(
    <nav className='app__photo-navigation'>
      <ul>
        <li onClick={prevImage}>prev</li>
        <li onClick={nextImage}>next</li>
      </ul>
    </nav>
  );
};

const Photo = (props) => {
  const { photoIndex, photoPath, dayNight, photos } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  return (
    <>
        {isMobile ? (
          <div className="app__body app__body--mobile-photos">
          {photos.map((photo, index) => (
            <img alt={`Photograph ${index + 1} of ${photos.length}.`} loading={index === 0 ? "eager" : "lazy"} src={`${process.env.PUBLIC_URL}/images/${dayNight}/${photo}`} />
          ))}
        </div>
        ) : (
          <div className="app__body">
            <img alt={`Photograph ${photoIndex + 1} of ${photos.length}.`} src={`${process.env.PUBLIC_URL}/images/${dayNight}/${photoPath}`} />
          </div>
        )}
    </>
  );
};

const DayNight = (props) => {
  const { dayNight, setDayNight, setPhotoIndex } = props;

  return (
    <nav className="app__daynight-navigation">
      <ul>
        <li onClick={() => { setDayNight("day"); setPhotoIndex(0); }} className={dayNight === "day" ? "active" : ""}>day</li>
        <li onClick={() => { setDayNight("night"); setPhotoIndex(0); }} className={dayNight === "night" ? "active" : ""}>night</li>
      </ul>
    </nav>
  );
}

const About = () => (
  <div className="app__body app__body--about">
    <img alt={`Shot of the photographer in a mirror at Papaya King.`} loading="lazy" src={`${process.env.PUBLIC_URL}/images/bio/bio-main-image.jpg`} />

    <p>Alexi grew up in the Hudson Valley north of NYC, in a hastily built condo complex incongruously stuck in the middle of the woods. Growing up in a place where there concerns of utility and economics outweighed all others has driven him to document and explore the fingerprints of our values as a society as they imprint themselves on the landscape and the places we live. Over the last two decades, he has used photography as a means of composing a record of fragmentary and subjective moments of attention, hoping that at the end something coherent will fall out. Sort of like reading tea leaves, only more expensive and time-consuming.</p>

    <p>Alexi lives and works in Brooklyn, NY, and tries to spend as much time in Berlin as possible between pandemics. He has a degree in painting from Skidmore college, and an MFA in creative writing from the City College of New York and is making a concerted effort to avoid accruing any additional credentials.</p>

    <p><em>Prints available on request.</em></p>

    <p>Contact: <strong>alexi at sine dot com</strong></p>

    <p>Instagram: <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/aleximaschas/">@aleximaschas</a></p>

    <h2>Film Scanning</h2>
    <p>Film scanning services available on request. I DSLR scan full rolls of 35mm, 120 and sheets of 4x5 at high resolution.</p>

    <h2>Jobo Repair</h2>
    <p>I repair most models of Jobo CPA, CPP and ATL processors. I can replace parts, repair circuits and in the worst case, rebuild the functionality with modern electronics. Service is limited to the NYC area for now.</p>
  </div>
)

const App = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [dayNight, setDayNight] = useState("day");

  return (
    <div className="app">
      <link rel="preload" href={`${process.env.PUBLIC_URL}/images/${dayNight}/${photos[dayNight][0]}`} as="image" />
      <Router>
        <header className="app__header">
          <h1 className="app__title">
            alexi<br />maschas
          </h1>
          <Routes>
            <Route path="/" element={<DayNight dayNight={dayNight} setDayNight={setDayNight} setPhotoIndex={setPhotoIndex} />}/>
          </Routes>
          <nav className="app__navigation">
            <ul>
              <li><NavLink to="/" className={active => active.isActive ? 'active' : ''}>photos</NavLink></li>
              <li><NavLink to="/about" className={active => active.isActive ? 'active' : ''}>about</NavLink></li>
            </ul>
          </nav>
          <Routes>
            <Route path="/" element={<PhotoNavigation photoIndex={photoIndex} setPhotoIndex={setPhotoIndex} dayNight={dayNight} />}/>
          </Routes>
        </header>
        <Routes>
          <Route path="/" element={<Photo photoIndex={photoIndex} photoPath={photos[dayNight][photoIndex]} photos={photos[dayNight]} dayNight={dayNight} />}/>
          <Route path="/about" element={<About />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
